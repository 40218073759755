.sidebar-main {
    position: fixed;
    height: 100vh;
    background-color: #fff;
    top: 0;
    padding-top: 20px;
   width: 14%;
    z-index: 5;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
}





.map-card {
    min-height: 93vh;
    position: absolute;
    left: 40px;
    min-width: 40vw;
    z-index: 1000;
    box-shadow: -10px 2px 10px rgba(112, 112, 112, 0.2);
    background-color: white;
    border-radius: 20px 0px 0px 20px;
    padding: 20px;

    -webkit-animation: slide1 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: slide1 0.5s forwards;
    animation-delay: 0s;
}

@media (max-width: 1175px) {
    .map-card {
        min-height: 91vh;
        left: 40px;
        min-width: 60vw;

        -webkit-animation: slide2 0.5s forwards;
        -webkit-animation-delay: 0s;
        animation: slide2 0.5s forwards;
        animation-delay: 0s;
    }
}

@media (max-width: 875px) {
    .map-card {
        min-height: 91vh;
        left: 40px;
        min-width: 80vw;

        -webkit-animation: slide3 0.5s forwards;
        -webkit-animation-delay: 2s;
        animation: slide3 0.5s forwards;
        animation-delay: 0s;
    }
}

.map-card-hide {
    position: absolute;
    left: -40.2vw;
    box-shadow: 0px 0px 0px rgba(112, 112, 112, 0.0);
    -webkit-animation: slide4 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide4 0.5s forwards;
    animation-delay: 0s;
}


