@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
	font-family: 'montserrat';
	font-size: 12px;
	// font-weight: 500;
}

h6 {
	font-size: 10px;
	font-weight: bolder;
}

h5 {
	font-size: 12px;
	font-weight: bold;
}

h4 {
	font-size: 14px;
	font-weight: 400;
}

h3 {
	font-size: 16px;
	font-weight: lighter;
}

h2 {
	font-size: 18px;
	font-weight: lighter;
}

h1 {
	font-size: 20px;
	font-weight: lighter;
}


.lead {
	font-size: 14px;
}

.react-bootstrap-table-page-btns-ul {
	margin-bottom: 0px;
}

.close {
	background: transparent;
	border: 0;
	font-size: 10px;
	padding: 1.35rem 1.25rem;
	background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0.5;
	width: 1em;
	height: 1em;
	z-index: 2;

	span {
		display: none;
	}
}

.rangeslider__fill {
	background-color: #556ee6 !important;
}

.rdw-editor-main {
	border: 1px solid $gray-300;
	height: 239px;
}

.dz-message {
	text-align: center;
	margin: 2em 0;
}

//Crypto enteries
.bs-select select {
	width: auto !important;
}

.apexcharts-canvas {
	text {
		fill: #adb5bd !important;
	}
}