// 
// _nav.scss
// 

.nav-tabs,
.nav-pills {
  >li {
    >a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  >a {
    color: black;
    font-weight: $font-weight-medium;
  }
}


.active-dropdown {
  color: $primary;
  font-weight: 800;
  font-size: 16px;
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item:hover .nav-link:hover {
    color: $secondary;
  }

  .nav-item {
    position: relative;
    color: rgb(0, 0, 0);



    .nav-link {
      border: none;
      color: rgb(0, 0, 0);
      z-index: 1;

      &::after {
        content: "";
        // background: $primary;
        height: 40px;

        position: absolute;
        color: rgb(0, 0, 0);
        width: 99%;
        left: 0;
        bottom: -2px;
        transition: all 250ms ease 0s;
        transform: scale(0);
        z-index: -5;
      }

      &.active {
        color: #9F73A9;
        border-bottom: 3px solid #9F73A9;
        background-color: #fafafa;
        z-index: -1;

      

        &:after {
          color: black;
          transform: scale(1);
        }
      }

    }
  }

  .active {

    color: white;
    // background-color: purple;
  }
}


// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;

      .nav-icon {
        font-size: 24px;
      }
    }
  }
}